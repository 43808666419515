import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// const hostname = window.location.hostname;

// const routes: Routes = [
//   { path: 'error', component: PageErrorComponent },
//   ...hostname !== '127.0.0.1' && hostname.split('.').length > 2 && !['test'].includes(hostname.split('.')?.[0])
//     ? [
//       { path: ':code', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
//       { path: ':code/:title', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
//     ]
//     : [
//       { path: ':code', component: PhotoEventsComponent },
//       { path: 'p/:code', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
//       { path: 'p/:code/:title', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
//     ],
//   { path: '**', component: PageNotFoundComponent }
// ];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(
      [
        { path: 'p/:code', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
        { path: 'p/:code/:title', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
        { path: ':code', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
        { path: ':code/:title', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
      ]
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
