import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

// {
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: [
//   //   environment.paymentsBaseUrl,
//   //   environment.usersBaseUrl,
//   //   environment.eventsBaseUrl,
//   //   environment.photosBaseUrl,
//   //   environment.notificationsBaseUrl,
//   // ],
//   routingInstrumentation: Sentry.routingInstrumentation,
// }

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.instrumentOutgoingRequests,
  ],
  // Performance Monitoring
  tracesSampleRate: environment.tracesSampleRate, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: environment.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
