import { APP_INITIALIZER, ErrorHandler, Inject, NgModule } from '@angular/core';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxNavigationAnimationModule } from '@uzenith360/ngx-navigation-animation';
import { NgxErrorPagesModule } from '@uzenith360/ngx-error-pages';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PhotoEventsComponent } from './photo-events/photo-events.component';
import { Router } from '@angular/router';
import { HOST, PATH, PROTOCOL } from './tokens';

@NgModule({
    declarations: [
        AppComponent,
        PhotoEventsComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatIconModule,
        NgxErrorPagesModule.forRoot({
            errorPage: {
                backText: 'home',
                backURL: '../',
                description: 'There was an error displaying this page',
                heading: '500 Page Error',
            },
            notFoundPage: {
                backText: 'home',
                backURL: '../',
                description: 'This link does not exist',
                heading: '404 Page Not Found',
            },
        }),
        NgxNavigationAnimationModule,
        BrowserAnimationsModule
    ],
    providers: [
        // {
        //     provide: HOST,
        //     useExisting: typeof window === 'undefined' ? undefined : window.location.host
        // },
        // {
        //     provide: PATH,
        //     useExisting: typeof window === 'undefined' ? undefined : window.location.pathname
        // },
        // {
        //     provide: PROTOCOL,
        //     useExisting: typeof window === 'undefined' ? undefined : window.location.protocol
        // },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                // showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        provideClientHydration(),
    ]
})
export class AppModule { }
