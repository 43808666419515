import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { fadeAnimation, NavigateEventService } from '@uzenith360/ngx-navigation-animation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeAnimation],
  providers: [NavigateEventService]
})
export class AppComponent implements OnInit {
  public title = 'Download files | Pixo';
  public isPageLoading: boolean = false;
  public page: string = this.document.location.pathname;
  public isProductsPage!: boolean;

  private navigateEventServiceSubscription!: Subscription;
  private navigateEventServiceStartSubscription!: Subscription;
  private navigateEventServiceStopSubscription!: Subscription;

  constructor(
    private readonly navigateEventService: NavigateEventService,
    @Inject(DOCUMENT) private readonly document: Document,
  ){
    this.navigateEventServiceSubscription = this.navigateEventService.navigation$.subscribe(
      (navigation) => {
        this.page = /*JSON.parse(navigation).url*/document.location.pathname;
        this.isProductsPage = JSON.parse(navigation).url.startsWith('/products');
      }
    );

    this.navigateEventServiceStartSubscription = this.navigateEventService
      .navigationStart$
      .subscribe(
        (navigation) => {
          this.isPageLoading = true;
        }
      );

    this.navigateEventServiceStopSubscription = this.navigateEventService
      .navigationStop$
      .subscribe(
        (navigation) => {
          setTimeout(() => {
            this.isPageLoading = false;
          }, 0);
        }
      );
  }
  ngOnInit(): void {}

  public getRouterOutletState(outlet: { isActivated: any; activatedRoute: any; }): void {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnDestroy(): void {
    if (this.navigateEventServiceSubscription) {
      this.navigateEventServiceSubscription.unsubscribe();
    }

    if (this.navigateEventServiceStartSubscription) {
      this.navigateEventServiceStartSubscription.unsubscribe();
    }

    if (this.navigateEventServiceStopSubscription) {
      this.navigateEventServiceStopSubscription.unsubscribe();
    }
  }
}
